import * as types from '../actions/actionTypes';


export default function editPlanReducer(initialState = null, action) {
  switch (action.type) {

    case types.editPlan:
            return action.payload
    default:
      return initialState;
  }
}