import * as types from '../actions/actionTypes';


export default function GetPlanReducer(initialState = null, action) {
  switch (action.type) {

    
    case types.GetPlan:
      return action.payload;

      case types.DeletePlan:
        return initialState = {
          count: initialState.count - 1,
          data: initialState.data.filter(user => user._id !== action.payload)
        }
        case types.changeplanStatus:
      return initialState ={
          count : initialState.count,
          data : initialState.data.map(ele =>{
            console.log(ele)
            if(ele._id == action.payload._id){
              ele = action.payload;
            }
            return ele;
          })
      }
    default:
      return initialState;
  }
}