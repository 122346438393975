import * as types from '../actions/actionTypes';


export default function DashboardReducer(initialState = null, action) {
  switch (action.type) {

    case types.getDashboardData:
      return action.payload;        
    
    default:
      return initialState;
  }
}