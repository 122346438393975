import * as types from '../actions/actionTypes';


export default function CompanyReducer(initialState = null, action) {
  switch (action.type) {

    
    case types.getCompany:
      return action.payload;

    case types.deleteCompany:
      return initialState = {
        count: initialState.count - 1,
        data: initialState.data.filter(user => user._id !== action.payload)
      }

    case types.Status:
      return initialState ={
          count : initialState.count,
          data : initialState.data.map(ele =>{
            if(ele._id == action.payload._id){
              ele = action.payload;
            }
            return ele;
          })
      }

      case types.AddCredit:
        return initialState ={
            count : initialState.count,
            data : initialState.data.map(ele =>{
              if(ele._id == action.payload._id){
                ele = action.payload;
              }
              return ele;
            })
        }

    default:
      return initialState;
  }
}