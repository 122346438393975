export const Login = 'Login';
export const AddUser = 'AddUser';
export const getDashboardData = 'getDashboardData';
export const isLoggedIn = 'isLoggedIn';
export const getCompany = 'getCompany';
export const deleteCompany = 'deleteCompany';
export const getParticularCompany = 'getParticularCompany';
export const UpdateCompany = 'UpdateCompany';
export const forgetPassword = 'forgetPassword';
export const resetPassword = 'resetPassword';
export const ViewCompany = 'ViewCompany';
export const getProfile = 'getProfile';
export const Status = 'Status';
export const Loading = 'Loading';
export const Report = 'Report';
export const AddPlan = 'AddPlan';
export const GetPlan = 'GetPlan';
export const DeletePlan = 'DeletePlan';
export const viewPlan = 'viewPlan';
export const editPlan = 'editPlan';
export const changeplanStatus = 'changeplanStatus';
export const AddCredit = 'AddCredit';
export const Transaction = 'Transaction';
export const ButtonLoader = 'ButtonLoader';

// console.log("enter in action")