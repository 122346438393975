import { combineReducers } from "redux";
import LoginReducer from './loginreducer';
import {reducer as toastrReducer} from 'react-redux-toastr'
import CompanyReducer from './companyreducer';
import DashboardReducer from './dashboardreducer';
import GetParticularDataReducer from './getParticularDatareducer';
import EditCompanyReducer from "./editCompanyreducer";
import IsLoggedInReducer from './isLoggedinreducer';
import ForgetpasswordReducer from "./forgetPassword";
import ResetPasswordReducer from "./resetPassword";
import ViewCompanyReducer from "./viewCompany";
import ProfileReducer from "./profilereducer";
import AddCompanyReducer from "./addCompanyReducer";
import LoadingReducer from "./loading";
import ReportReducer from "./reportReducer";
import AddPlanReducer from "./addPlan";
import GetPlanReducer from "./getPlans";
import ViewPlanReducer from './viewPlan'
import editPlanReducer from "./editPlanReducer";
import GetTransactionReducer from "./getTransaction";
import ButtonLoaderReducer from "./buttonLoader";
const rootReducer = combineReducers({
   loginR : LoginReducer,
   toastr : toastrReducer,
   companyR : CompanyReducer,
   addCompanyR : AddCompanyReducer,
   dashboardR : DashboardReducer,
   getParticularDataR : GetParticularDataReducer,
   editCompanyR : EditCompanyReducer,
   isLoggedInR : IsLoggedInReducer,
   forgetPasswordR : ForgetpasswordReducer,
   resetpasswordR : ResetPasswordReducer,
   viewCompanyR : ViewCompanyReducer,
   profileR : ProfileReducer,
   loadingR : LoadingReducer,
   reportR : ReportReducer,
   addPlanR : AddPlanReducer,
   getPlanR : GetPlanReducer,
   ViewPlanR :ViewPlanReducer,
   editPlanR : editPlanReducer,
   getTransactionR : GetTransactionReducer,
   ButtonLoaderR : ButtonLoaderReducer,
   

});

export default rootReducer;