import * as types from '../actions/actionTypes';


export default function ButtonLoaderReducer(initialState = false, action) {
    switch (action.type) {

        case types.ButtonLoader:
            return action.payload
        default:
            return initialState;
    }
}