import * as types from '../actions/actionTypes';


export default function AddCompanyReducer(initialState = null, action) {
  switch (action.type) {

    case types.AddUser:
            return action.payload

    default:
      return initialState;
  }
}